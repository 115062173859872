<template>
    <div class="app">

        <Navbar/>

        <div v-if="$route.params.hash" class="content">
            <div class="status-wrapper confirming-email">
                <div class="loader"></div>
                <h1 class="less-margin">Confirming email, please wait</h1>
                <h3>You will be redirected shortly.</h3>
            </div>
        </div>
        <div v-else class="content">
            <div class="status-wrapper confirm-email">
                <div class="loader"></div>
                <h1 class="less-margin">Please confirm your email</h1>
                <h3>We have sent an email to <span class="blue">onlyqubes@gmail.com</span> to confirm that you own it. Please follow the
                    instructions that we have sent to your inbox to confirm.</h3>
                <h5>If you have not received the email click the "re-send email" button and give it up to 5 minutes to
                    receive the email. It can also be in the Spam category.</h5>
                <button class="button-primary-2">Re-send email</button>
                <a class="button-logout" href="#">Don't own that email? Log out</a>
            </div>
        </div>
    </div>
</template>

<script>
  import Navbar from '../../components/global/navbar/Navbar';
  export default {
    components: { Navbar }
  };
</script>

<style scoped>
@import '../../assets/css/payment.css';
</style>